import styles from './Termos.module.css';
import BrainBuzz from './../Images/brainbuzz.png';
import EraUmaVez from './../Images/eraumavez.png';
import Grimorio from './../Images/grimorio.jpeg';
import { Link } from 'react-router-dom';

export default function Home() {

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.buttons}>
                    <Link to={'/brainbuzz'} className={styles.button}>
                        <img src={BrainBuzz} alt="BrainBuzz" className={styles.picture} />
                    </Link>
                    <Link to={'/eraumavez'} className={styles.button}>
                        <img src={EraUmaVez} alt="Era uma vez" className={styles.picture} />
                    </Link>
                    <Link to={'/grimorio'} className={styles.button}>
                        <img src={Grimorio} alt="Contos Sombrios" className={styles.picture} />
                    </Link>
                </div>
            </div>
        </div>
    )
}
