import styles from './Termos.module.css';
import Picture from './../Images/brainbuzz.png';

export default function BrainBuzz() {

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <img className={styles.picture} src={Picture} alt="BrainBuzz" />
                <div className={styles.title}>BrainBuzz</div>
                <div className={styles.content}>
                    <div className={styles.topic}>Termos e Condições - BrainBuzz</div>
                    <div className={styles.text}>Última atualização: 22/05/2023</div>
                    <div className={styles.text}>Bem-vindo(a) ao "BrainBuzz". Ao utilizar nosso aplicativo e/ou acessar nosso site, você concorda em cumprir os seguintes termos e condições ("Termos") e nossa Política de Privacidade. Certifique-se de ler atentamente esses Termos antes de utilizar o aplicativo ou o site.</div>
                    <div className={styles.topic}>1. Uso do Aplicativo</div>
                    <div className={styles.text}>1.1. O "BrainBuzz" é um aplicativo de quiz destinado a oferecer entretenimento e desafios educacionais para usuários de todas as idades. Ao utilizar o aplicativo, você concorda em respeitar todas as políticas e diretrizes relacionadas à faixa etária apropriada e ao conteúdo adequado para todas as idades.</div>
                    <div className={styles.text}>1.2. Você é responsável por qualquer informação fornecida durante o uso do aplicativo. Certifique-se de que todas as informações fornecidas sejam verdadeiras, precisas e atualizadas.</div>
                    <div className={styles.text}>1.3. O conteúdo disponível no aplicativo, incluindo perguntas, respostas e recursos visuais, é protegido por direitos autorais e é de propriedade do "BrainBuzz" ou de terceiros licenciados. Você concorda em não copiar, reproduzir, distribuir ou criar trabalhos derivados do conteúdo sem autorização prévia por escrito.</div>
                    <div className={styles.topic}>2. Sistema de Login</div>
                    <div className={styles.text}>2.1. O "BrainBuzz" oferece a opção de login através da conta do Google. Ao utilizar essa opção, você concorda em compartilhar informações básicas do seu perfil, incluindo nome, e-mail e foto, conforme autorizado pela sua conta do Google.</div>
                    <div className={styles.topic}>3. Privacidade e Proteção de Dados</div>
                    <div className={styles.text}>3.1. Respeitamos sua privacidade e levamos a proteção de dados a sério. Ao utilizar nosso aplicativo, você concorda com a coleta, uso e armazenamento de informações pessoais, conforme estabelecido em nossa Política de Privacidade. Certifique-se de ler atentamente nossa Política de Privacidade para entender como suas informações são coletadas, usadas e protegidas.</div>
                    <div className={styles.topic}>4. Responsabilidade e Limitações</div>
                    <div className={styles.text}>4.1. O "BrainBuzz" se esforça para fornecer um aplicativo seguro e livre de erros. No entanto, não podemos garantir que o aplicativo estará livre de interrupções, bugs ou falhas técnicas. O uso do aplicativo é de sua responsabilidade e você concorda em utilizá-lo por sua conta e risco.</div>
                    <div className={styles.text}>4.2. Não nos responsabilizamos por quaisquer danos diretos, indiretos, incidentais, consequenciais ou punitivos resultantes do uso ou incapacidade de usar o aplicativo, incluindo perda de dados, lucros ou oportunidades de negócio.</div>
                    <div className={styles.topic}>5. Modificações e Atualizações</div>
                    <div className={styles.text}>5.1. Reservamos o direito de modificar, atualizar ou encerrar o aplicativo a qualquer momento, sem aviso prévio. Essas modificações podem incluir a adição, alteração ou remoção de recursos ou funcionalidades.</div>
                    <div className={styles.text}>5.2. Recomendamos que você mantenha o aplicativo atualizado para desfrutar dos recursos mais recentes e corrigir eventuais problemas de desempenho ou segurança.</div>
                    <div className={styles.topic}>6. Contato</div>
                    <div className={styles.text}>6.1. Se tiver dúvidas, preocupações ou comentários sobre o "BrainBuzz" ou estes Termos, entre em contato conosco através das informações fornecidas em nosso site.</div>
                </div>
                <div className={styles.content}>
                    <div className={styles.topic}>Política de Privacidade - BrainBuzz</div>
                    <div className={styles.text}>Última atualização: 22/05/2023</div>
                    <div className={styles.text}>Agradecemos por utilizar o aplicativo "BrainBuzz". Nesta política de privacidade, explicamos como coletamos, usamos, armazenamos e protegemos as informações pessoais dos usuários. Ao utilizar nosso aplicativo, você concorda com esta política de privacidade.</div>
                    <div className={styles.topic}>1. Informações Coletadas</div>
                    <div className={styles.text}>1.1. Informações Fornecidas pelos Usuários:</div>
                    <div className={styles.text}>Durante o uso do aplicativo, podemos coletar as seguintes informações fornecidas pelos usuários:</div>
                    <div className={styles.text}>- Informações de registro: nome, e-mail e outras informações necessárias para criar uma conta de usuário.</div>
                    <div className={styles.text}>- Informações de perfil: foto do perfil, caso seja fornecida pelo usuário.</div>
                    <div className={styles.topic}>1.2. Informações Coletadas Automaticamente</div>
                    <div className={styles.text}>Podemos coletar automaticamente determinadas informações sobre o uso do aplicativo, incluindo:</div>
                    <div className={styles.text}>- Informações de dispositivo: modelo do dispositivo, sistema operacional, versão do aplicativo e identificadores exclusivos do dispositivo.</div>
                    <div className={styles.text}>- Informações de uso: dados de uso, como perguntas respondidas, tempo gasto no aplicativo e estatísticas relacionadas ao uso do aplicativo.</div>
                    <div className={styles.topic}>2. Uso das Informações</div>
                    <div className={styles.text}>2.1. Utilizamos as informações coletadas para os seguintes propósitos:</div>
                    <div className={styles.text}>- Fornecer e melhorar o aplicativo, incluindo personalização de conteúdo e recursos.</div>
                    <div className={styles.text}>- Processar transações e fornecer suporte relacionado a pagamentos, se aplicável.</div>
                    <div className={styles.text}>- Enviar atualizações, notificações e comunicações relacionadas ao aplicativo.</div>
                    <div className={styles.text}>- Responder a perguntas, solicitações de suporte e outras consultas dos usuários.</div>
                    <div className={styles.text}>- Analisar o uso do aplicativo e coletar informações estatísticas para melhorar nossa oferta.</div>
                    <div className={styles.topic}>3. Compartilhamento de Informações</div>
                    <div className={styles.text}>3.1. Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes circunstâncias:</div>
                    <div className={styles.text}>- Com sua autorização explícita.</div>
                    <div className={styles.text}>- Para cumprir requisitos legais, regulatórios ou governamentais.</div>
                    <div className={styles.text}>- Com provedores de serviços terceirizados que nos auxiliam na operação e manutenção do aplicativo.</div>
                    <div className={styles.text}>- Em caso de fusão, aquisição ou venda de ativos, onde as informações do usuário possam ser transferidas como parte da transação.</div>
                    <div className={styles.topic}>4. Proteção de Informações</div>
                    <div className={styles.text}>4.1. Empregamos medidas de segurança adequadas para proteger as informações pessoais dos usuários contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhum método de transmissão pela Internet ou armazenamento eletrônico é completamente seguro, e não podemos garantir a segurança absoluta das informações.</div>
                    <div className={styles.topic}>5. Direitos dos Usuários</div>
                    <div className={styles.text}>5.1. Os usuários têm direito a acessar, corrigir, atualizar ou excluir suas informações pessoais. Para exercer esses direitos, entre em contato conosco através das informações fornecidas no final desta política.</div>
                    <div className={styles.topic}>6. Alterações nesta Política</div>
                    <div className={styles.text}>6.1. Podemos atualizar esta política de privacidade periodicamente. A versão mais recente estará disponível no aplicativo. Notificaremos os usuários sobre quaisquer alterações significativas enviando um aviso para o endereço de e-mail fornecido ou por meio de um aviso dentro do aplicativo.</div>
                    <div className={styles.topic}>7. Contato</div>
                    <div className={styles.text}>7.1. Se tiver dúvidas, preocupações ou comentários sobre esta política de privacidade, entre em contato conosco através das informações fornecidas em nosso site.</div>
                </div>
                <div className={styles.content}>
                    <div className={styles.topic}>Exclusão de Conta - BrainBuzz</div>
                    <div className={styles.text}>Última atualização: 22/05/2023</div>
                    <div className={styles.topic}>Solicitação de exclusão de conta</div>
                    <div className={styles.text}>Abra o cliente de e-mail no qual você está registrado com sua conta BrainBuzz.</div>
                    <div className={styles.text}>Clique em "Novo E-mail" ou equivalente, dependendo do cliente de e-mail que você está usando.</div>
                    <div className={styles.text}>No campo "Para", insira o endereço de e-mail de contato para exclusão de conta, que é <a href="mailto:contato@tosostudio.com">contato@tosostudio.com</a>.</div>
                    <div className={styles.text}>No campo "Assunto", insira "Exclusão de Conta".</div>
                    <div className={styles.text}>No corpo do e-mail, forneça as informações necessárias para a exclusão da conta, como:
                        <ul>
                            <div className={styles.text}>Seu nome de usuário ou endereço de e-mail associado à conta BrainBuzz.</div>
                            <div className={styles.text}>Informe que deseja excluir sua conta e que você está ciente das consequências dessa ação.</div>
                            <div className={styles.text}>Qualquer outra informação solicitada pela plataforma para processar a exclusão.</div>
                        </ul>
                    </div>
                    <div className={styles.text}>Após preencher o e-mail com as informações necessárias, clique em "Enviar" para enviar a solicitação de exclusão de conta.</div>
                    <div className={styles.text}>Aguarde a confirmação da equipe de suporte da BrainBuzz. Eles podem precisar de informações adicionais ou fornecer instruções adicionais para completar o processo de exclusão.</div>
                </div>
            </div>
        </div >
    )
}
