import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import EraUmaVez from "./Pages/EraUmaVez";
import BrainBuzz from "./Pages/BrainBuzz";
import Grimorio from "./Pages/Grimorio";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/eraumavez" element={<EraUmaVez />} />
        <Route path="/brainbuzz" element={<BrainBuzz />} />
        <Route path="/grimorio" element={<Grimorio />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
